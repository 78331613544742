import React, { useEffect, useState } from 'react'
import { Transition } from '@headlessui/react'
import { InternalLink } from '@/atoms/InternalLink'
import { useUser } from '@/services/UserService'
import { useLoginUrl } from '@/utils/auth-utils'
import { useTranslate } from '@/utils/translate/translate-client'

interface AlreadyAGuildMemberProps {
  className?: string
  linkClassName?: string
}

export const AlreadyAGuildMember: React.FC<AlreadyAGuildMemberProps> = ({
  className = 'mt-4 text-center text-[14px] text-gray-300',
  linkClassName = 'font-semibold text-white underline',
}) => {
  const [isMounted, setMounted] = useState(false)
  const { isLoggedIn, loading } = useUser()
  const { t } = useTranslate('guild')
  const { loginUrl, trackLoginStarted } = useLoginUrl()

  useEffect(() => {
    setMounted(true)
  }, [])

  const isLoading = !isMounted || loading
  const isShow = !isLoading && !isLoggedIn

  return (
    <>
      {isLoading && <div className="h-[37px]" />}

      {isShow && (
        <Transition
          show
          appear
          enter="transition-opacity duration-1000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-1000"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={className}>
            {t('alreadyAGuildMember', 'Already a Guild Member?')}{' '}
            <InternalLink
              className={linkClassName}
              href={loginUrl}
              onClick={() => {
                trackLoginStarted('guild-membership-pass-cta')
              }}
            >
              {t('signIn', 'Sign In')}
            </InternalLink>
          </div>
        </Transition>
      )}
    </>
  )
}
