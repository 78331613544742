import React from 'react'
import classNames from 'classnames'
import { EarlyAccessIcon } from '@/atoms/Icons/EarlyAccessIcon'
import { EpisodeIcon } from '@/atoms/Icons/EpisodeIcon'
import { InfiniteIcon } from '@/atoms/Icons/InfiniteIcon'
import { PlaySquareIcon } from '@/atoms/Icons/PlaySquareIcon'
import { TicketIcon } from '@/atoms/Icons/TicketIcon'
import { Image } from '@/atoms/Image'
import { ResponsiveBackgroundImage } from '@/atoms/ResponsiveBackgroundImage'
import { AsH3, CaptionXS, TitleXS } from '@/atoms/Text'
import {
  buildCloudinaryImageUrlFromContentfulObject,
  formatContentfulObjectFromCloudinaryUrl,
} from '@/utils/Cloudinary'
import { useSafeTrack } from '@/utils/analytics'
import { Release } from './types'

export interface ReleaseCardProps {
  release: Release
  backgroundClassName?: string
}

const renderIcon = (perkType: string) => {
  switch (perkType) {
    case 'access':
      return <EarlyAccessIcon color={'white'} size={14} />
    case 'tickets':
      return <TicketIcon color={'white'} size={14} />
    case 'episode':
      return <EpisodeIcon color={'white'} size={14} />
    case 'watch':
      return <PlaySquareIcon color={'white'} size={14} />
    case 'unlimited':
      return <InfiniteIcon color={'white'} size={14} />
    default:
      return null
  }
}

export const ReleaseCard: React.FC<ReleaseCardProps> = ({ release, backgroundClassName = 'bg-core-gray-950' }) => {
  const track = useSafeTrack()

  return (
    <div
      key={release.name}
      className="mb-4 flex flex-row gap-2 text-left"
      onClick={() =>
        track('Guild Release Card Clicked', {
          context: 'Guild Membership Early Access Page Release Card',
          release: release.name,
        })
      }
    >
      <div className="flex w-[50px] flex-col justify-center py-3 text-center">
        <div className={classNames('z-10 py-4', backgroundClassName)}>
          {release.caption && release.captionSize && (
            <div className={'font-semibold'} style={{ fontSize: release.captionSize }}>
              {release.caption}
            </div>
          )}
          <div className="text-[13px] font-semibold uppercase">{release?.releaseMonth}</div>
          <div className="text-[32px] font-semibold uppercase leading-9">{release?.releaseDay}</div>
          <div className="text-[13px] font-semibold">{release?.releaseYear}</div>
        </div>
      </div>
      <div className="flex w-full flex-row overflow-hidden rounded-xl">
        <Image
          src={buildCloudinaryImageUrlFromContentfulObject(release.verticalPoster, 'h_134,w_90,c_fill')}
          alt={release.name}
          height={134}
          width={90}
          className="shrink-0 grow-0"
        />
        <div className="relative flex grow">
          <ResponsiveBackgroundImage
            src={
              release.horizontalPoster
                ? buildCloudinaryImageUrlFromContentfulObject(release.horizontalPoster)
                : buildCloudinaryImageUrlFromContentfulObject(
                    formatContentfulObjectFromCloudinaryUrl('v1694104235/Show.png'),
                  )
            }
            className="h-full w-full bg-cover bg-center bg-no-repeat"
          />
          <div className="flex h-full w-full flex-col bg-white/[0.015] px-3 py-[15px] backdrop-blur-3xl">
            <TitleXS as={AsH3} className="mb-1 w-full">
              {release.name}
            </TitleXS>
            <CaptionXS className="w-full font-light">{release.description}</CaptionXS>
            <div className="mt-2 flex w-fit flex-row items-center gap-1 overflow-hidden rounded-3xl bg-core-gray-300/[0.25] px-2.5 py-1 text-[10px] uppercase">
              {renderIcon(release.perkType)}
              <span className="mr-0.5 pt-[1px] font-medium">{release.perk}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
