import dynamic, { DynamicOptions } from 'next/dynamic'
import { GuildPromotionCountdownProps } from './GuildPromotionCountdown'
import { GuildPromotionCountdownSimplifiedProps } from './GuildPromotionCountdownSimplified'

export const GuildPromotionCountdown = dynamic(
  import('./GuildPromotionCountdown')
    .then((mod) => mod.GuildPromotionCountdown)
    .catch((err) =>
      // eslint-disable-next-line no-console
      console.error(`Failed to load the Countdown!`, err),
    ) as DynamicOptions<GuildPromotionCountdownProps>,
  { ssr: false },
)

export const GuildPromotionCountdownSimplified = dynamic(
  import('./GuildPromotionCountdownSimplified')
    .then((mod) => mod.GuildPromotionCountdownSimplified)
    .catch((err) =>
      // eslint-disable-next-line no-console
      console.error(`Failed to load the Countdown!`, err),
    ) as DynamicOptions<GuildPromotionCountdownSimplifiedProps>,
  { ssr: false },
)
