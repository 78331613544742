import React, { useMemo } from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Marquee } from '@devnomic/marquee'
import classNames from 'classnames'
import { When } from 'react-if'
import LazyHydrate from 'react-lazy-hydration'
import { LinkButton } from '@/atoms/Button'
import { GuildHighResShieldIcon } from '@/atoms/Icons/GuildHighResShieldIcon'
import { ResponsiveBackgroundImage } from '@/atoms/ResponsiveBackgroundImage'
import { AsH1, AsSpan, HeadingXS, LabelSM, ParagraphSM } from '@/atoms/Text'
import { options } from '@/services/ContentfulService'
import { isGuildSignupScrollingLayout } from '@/services/GuildSignupPageService/validations'
import { useTranslate } from '@/utils/translate/translate-client'
import useWindowSize from '@/utils/useWindowSize'
import { GuildEmailInput } from '@/views/GuildJoinView/GuildEmailInput'
import { useGuildSignupContext } from '@/views/GuildSignupView/GuildSignupContext'

interface GuildScrollingHeroProps {
  hasUser: boolean
}
export const GuildScrollingHero: React.FC<GuildScrollingHeroProps> = ({ hasUser }) => {
  const { layout } = useGuildSignupContext()
  const { t } = useTranslate('guild')
  const { innerWidth, innerHeight } = useWindowSize()

  const imageHeight = useMemo(() => {
    return innerWidth > 2000
      ? '400px'
      : innerWidth > 1300
      ? '300px'
      : innerWidth > 768
      ? '220px'
      : innerHeight > 670
      ? '165px'
      : '140px'
  }, [innerWidth, innerHeight])

  const imageWidth = useMemo(() => {
    return innerWidth > 2000
      ? '267px'
      : innerWidth > 1300
      ? '200px'
      : innerWidth > 768
      ? '147px'
      : innerHeight > 670
      ? '110px'
      : '93px'
  }, [innerWidth, innerHeight])

  const imageZoom = useMemo(() => {
    return innerWidth > 2000 ? 1.33 : innerWidth > 1300 ? 0 : innerWidth > 768 ? 0.73 : innerHeight > 670 ? 0.55 : 0.46
  }, [innerWidth, innerHeight])

  if (!isGuildSignupScrollingLayout(layout)) {
    return null
  }

  const scrollingLayout = layout
  const showHeroCta = false //Boolean(scrollingLayout.heroCta) turning off while we have the pricing plans present
  const sprite = 'https://images.angelstudios.com/image/upload/v1732132444/angel-studios/guild/hero/css_sprites_3.webp'

  const CABRINI = { x: -10, y: -10 }
  const SOUND_OF_HOPE_THE_STORY_OF_POSSUM_TROT = { x: -230, y: -10 }
  const SWEETWATER = { x: -450, y: -10 }
  const SOUND_OF_FREEDOM = { x: -670, y: -10 }
  const HOMESTEAD = { x: -890, y: -10 }
  const HIS_ONLY_SON = { x: -10, y: -330 }
  const BONHOEFFER = { x: -230, y: -330 }
  const SOMETHING_TO_STAND_FOR_WITH_MIKE_ROWE = { x: -450, y: -330 }
  const THE_SHIFT = { x: -670, y: -330 }
  const JUNGLE_BEAT = { x: -890, y: -330 }
  const TUTTLE_TWINS = { x: -10, y: -650 }
  const WINGFEATHER_SAGA = { x: -230, y: -650 }
  const DRY_BAR = { x: -450, y: -650 }
  const RIOT = { x: -670, y: -650 }
  const SIGHT = { x: -890, y: -650 }

  const firstColumn = [CABRINI, SOUND_OF_HOPE_THE_STORY_OF_POSSUM_TROT, TUTTLE_TWINS, DRY_BAR, HIS_ONLY_SON]

  const secondColumn = [WINGFEATHER_SAGA, SOUND_OF_FREEDOM, SOMETHING_TO_STAND_FOR_WITH_MIKE_ROWE, RIOT, HOMESTEAD]

  const thirdColumn = [BONHOEFFER, THE_SHIFT, SWEETWATER, SIGHT, JUNGLE_BEAT]

  return (
    <section
      aria-label={scrollingLayout?.heading ?? undefined}
      className="2xl:max-h-auto relative h-full min-h-screen w-full overflow-hidden"
    >
      <link
        rel="preload"
        href="https://images.angelstudios.com/image/upload/v1731967123/angel-studios/guild/css_sprites.png"
        as="image"
      ></link>
      {scrollingLayout?.backgroundHeroImage && (
        <ResponsiveBackgroundImage
          src={scrollingLayout.backgroundHeroImage}
          className="pointer-events-none touch-none select-none"
        />
      )}
      <div className="absolute left-0 top-0 z-10 h-screen min-h-screen w-full bg-transparent px-4 md:max-h-[800px] md:px-8 xl:mx-[40px] xl:px-4">
        <div
          className={classNames(
            'relative flex h-full w-full flex-col-reverse lg:!flex-row items-center justify-center 2xl:mx-auto 2xl:max-w-[1600px]',
            {
              '!justify-start !mt-8 lg:!mt-0 lg:!justify-center': showHeroCta,
            },
          )}
        >
          <div className="-mt-[40vh] flex h-full w-full flex-col items-start justify-center gap-y-4 bg-transparent py-0 opacity-100 md:items-center md:gap-y-6 lg:-mt-40 lg:w-1/2 lg:items-start lg:py-32 2xl:pl-10">
            <div>
              <When condition={!showHeroCta}>
                <div className="flex flex-col items-center gap-y-1">
                  <GuildHighResShieldIcon className="invisible h-0 w-0 md:visible md:h-full md:w-fit" size={64} />
                  <GuildHighResShieldIcon
                    className="invisible h-0 w-0 sm:visible sm:h-full sm:w-fit md:invisible md:h-0 md:w-0"
                    size={48}
                  />
                  <ParagraphSM color="white" className="hidden uppercase md:flex">
                    {t('guild', 'Guild')}
                  </ParagraphSM>
                </div>
              </When>
            </div>
            <div className="z-20 -mt-[80px] flex max-w-[600px] flex-col gap-y-4 md:mt-0 se:max-w-full ">
              <HeadingXS
                as={AsH1}
                weight="bold"
                className="se:photon-title-lg md:photon-heading-lg 2xl:photon-heading-xl w-full text-pretty bg-transparent text-center md:text-left"
                color="white"
              >
                {scrollingLayout.heading}
              </HeadingXS>
              <ParagraphSM
                as={AsSpan}
                className="[&>p]:photon-paragraph-sm md:[&>p]:photon-paragraph-md se:[&>p]:photon-caption-sm 2xl:[&>p]:photon-paragraph-xl -mb-4 w-full text-pretty bg-transparent text-center md:text-left"
                color="core-gray-400"
              >
                {documentToReactComponents(scrollingLayout?.paragraph?.json, options)}
              </ParagraphSM>
            </div>
            <div className="flex w-full max-w-[600px] flex-col !opacity-100">
              <GuildEmailInput hasUser={hasUser} isCentered={false} sideBySide={false} />
            </div>
          </div>
          <div className="z-10 mb-[45px] flex h-1/3 w-full justify-center gap-x-4 opacity-70 lg:my-0 lg:h-full lg:w-1/2 lg:justify-center lg:opacity-100">
            <LazyHydrate whenVisible>
              <>
                {innerWidth && innerHeight && (
                  <Marquee direction="up" reverse className="fade-marquee h-full w-1/3 animate-fadein md:w-auto">
                    {firstColumn?.map((poster) => {
                      const background = `url('${sprite}') ${poster.x}px ${poster.y}px`
                      return (
                        <div
                          key={JSON.stringify({ poster })}
                          style={{
                            height: imageHeight,
                            width: imageWidth,
                          }}
                          className="rounded-2xl"
                        >
                          <div
                            style={{
                              background,
                              zoom: imageZoom,
                            }}
                            className="pointer-events-none h-full w-full max-w-full touch-none select-none rounded-2xl bg-center"
                          />
                        </div>
                      )
                    })}
                  </Marquee>
                )}
              </>
            </LazyHydrate>
            <LazyHydrate whenVisible>
              <>
                {innerWidth && innerHeight && (
                  <Marquee
                    direction="up"
                    className="fade-marquee h-full w-1/3 animate-fadein [--duration:32s] md:w-auto"
                    innerClassName="[--duration:32s]"
                  >
                    {secondColumn?.map((poster) => {
                      const background = `url('${sprite}') ${poster.x}px ${poster.y}px`
                      return (
                        <div
                          key={JSON.stringify({ poster })}
                          style={{
                            height: imageHeight,
                            width: imageWidth,
                          }}
                          className="rounded-2xl"
                        >
                          <div
                            style={{
                              background,
                              zoom: imageZoom,
                            }}
                            className="pointer-events-none h-full w-full max-w-full touch-none select-none rounded-2xl bg-center "
                          />
                        </div>
                      )
                    })}
                  </Marquee>
                )}
              </>
            </LazyHydrate>
            <LazyHydrate whenVisible>
              <>
                {innerWidth && innerHeight && (
                  <Marquee direction="up" reverse className="fade-marquee w-1/3 animate-fadein md:w-auto">
                    {thirdColumn?.map((poster) => {
                      const background = `url('${sprite}') ${poster.x}px ${poster.y}px`
                      return (
                        <div
                          key={JSON.stringify({ poster })}
                          style={{
                            height: imageHeight,
                            width: imageWidth,
                          }}
                          className="rounded-2xl"
                        >
                          <div
                            style={{
                              background,
                              zoom: imageZoom,
                            }}
                            className="pointer-events-none h-full w-full max-w-full touch-none select-none rounded-2xl bg-center"
                          />
                        </div>
                      )
                    })}
                  </Marquee>
                )}
              </>
            </LazyHydrate>
          </div>
        </div>
      </div>
      <div
        className="absolute inset-0 h-full w-[130%]"
        style={{ background: 'linear-gradient(180deg, rgba(20, 20, 20, 0.00) 0%, #222222 100%)' }}
      />
      <When condition={showHeroCta}>
        <div
          className="absolute bottom-0 left-[-50%] z-30 w-[200%] pt-1 text-white sm:left-[-40%] sm:w-[180%] md:left-[-25%] md:w-[150%] lg:left-[-15%] lg:w-[130%]"
          style={{
            background: 'linear-gradient(to bottom right, #EDD7C6, #D6A177, #FEDEC6, #D48D59, #A66141, #612B12)',
            borderTopLeftRadius: '50% 100%',
            borderTopRightRadius: '50% 100%',
            boxShadow:
              innerWidth > 768 ? '0px 0px 75px 75px rgba(0, 0, 0, 0.80)' : '0px 0px 20px 20px rgba(0, 0, 0, 0.60)',
          }}
        >
          <div
            className="flex w-full max-w-full flex-col items-center justify-center gap-2 bg-[#222222] pb-4 pt-2 md:flex-row md:gap-4 md:pt-12 lg:pt-10 xl:min-h-[180px] xl:pt-6"
            style={{ borderTopLeftRadius: '50% 100%', borderTopRightRadius: '50% 100%' }}
          >
            <div>
              <GuildHighResShieldIcon className="invisible h-0 w-0 md:visible md:h-full md:w-fit" size={64} />
              <GuildHighResShieldIcon
                className="invisible h-0 w-0 sm:visible sm:h-full sm:w-fit md:invisible md:h-0 md:w-0"
                size={48}
              />
              <GuildHighResShieldIcon className="visible h-auto w-auto sm:invisible sm:h-0 sm:w-0" size={36} />
            </div>
            <div className="relative !z-[9999] flex max-w-[95vw] flex-col justify-between gap-2 rounded-2xl bg-core-gray-800 p-4 md:flex-row md:gap-10">
              {scrollingLayout.heroCta?.description && (
                <ParagraphSM className="lg:photon-paragraph-md max-w-[364px] lg:max-w-[416px] se:max-w-[312px] se:text-xs">
                  {scrollingLayout.heroCta.description}
                </ParagraphSM>
              )}
              <LinkButton
                variant="black"
                className="!mb-0 max-h-[54px] max-w-[342px] !cursor-pointer whitespace-nowrap rounded-[14px] !border-core-gray-950 !bg-core-gray-950 px-8 py-[14px]"
                href={scrollingLayout.heroCta?.buttonHref ?? '#plan-selection'}
              >
                <LabelSM color="white" className="lg:photon-label-lg !font-medium">
                  {scrollingLayout.heroCta?.buttonText ?? t('learnMore', 'Learn More')}
                </LabelSM>
              </LinkButton>
            </div>
          </div>
        </div>
      </When>
    </section>
  )
}
