import React from 'react'
import classNames from 'classnames'
import { ResponsiveBackgroundImage } from '@/atoms/ResponsiveBackgroundImage'
import { isGuildSignupSingleShowLayout } from '@/services/GuildSignupPageService/validations'
import { useGuildSignupContext } from '../GuildSignupContext'
import { GuildHeroCTA } from './GuildHeroCTA'

export const GuildSingleShowLayout: React.FC = () => {
  const { layout } = useGuildSignupContext()

  if (!isGuildSignupSingleShowLayout(layout)) return null

  const { heroImages } = layout

  return (
    <div className="h-screen w-screen overflow-y-hidden">
      <div className="relative h-screen w-screen overflow-y-hidden bg-cover">
        <ResponsiveBackgroundImage
          src={heroImages.desktop}
          srcUrls={{
            xs: heroImages.mobile,
            sm: heroImages.mobile,
            md: heroImages.tablet,
            lg: heroImages.desktop,
            xl: heroImages.ultra,
          }}
          className={classNames('h-screen lg:bg-top', heroImages.position)}
        />
        <div className="absolute inset-0 bg-gradient-to-t from-[#222222]" />
      </div>
      <GuildHeroCTA />
    </div>
  )
}
