import { Image } from '@/atoms/Image'
import { AsH3, CaptionSM, EyebrowMD, TitleXS } from '@/atoms/Text'
import { Episode } from '@/types/codegen-contentful'
import { ReactFCC } from '@/types/react'
import { useLocale } from '@/utils/LocaleUtil'
import { useTranslate } from '@/utils/translate/translate-client'

interface CatalogCardProps {
  content: Episode
}

export const ContentCard: ReactFCC<CatalogCardProps> = ({ content }) => {
  const { t } = useTranslate('guild')
  const { locale } = useLocale()
  const releaseDate = new Date(content.releaseDate)

  if (!content) return null

  const now = Date.now()
  const farFutureDate = new Date(2050, 0, 0).getTime()

  const availableDateText =
    releaseDate && releaseDate.getTime() > farFutureDate
      ? t('comingSoon', 'Coming Soon')
      : releaseDate && releaseDate.getTime() > now
      ? t('availableDate', 'Available on {{date}}', {
          date: Intl.DateTimeFormat(locale, { dateStyle: 'medium' }).format(releaseDate),
        })
      : t('availableNow', 'Available Now')

  return (
    <div className="group flex w-[250px] flex-col gap-8">
      <div className="relative h-[375px] w-[250px] overflow-hidden rounded-2xl">
        {content.imageUrl && <Image alt={content.sys.id} height={375} width={250} src={content.imageUrl} />}
      </div>
      <div className="flex flex-col gap-6 opacity-100 duration-500 ease-in group-hover:opacity-100 group-active:opacity-100 lg:opacity-30">
        <div className="flex flex-col gap-1">
          <TitleXS as={AsH3} weight="bold">
            {content.title}
          </TitleXS>
          {content.description && <CaptionSM className="line-clamp-2">{content.description}</CaptionSM>}
        </div>
        <div className="rounded bg-black py-1 text-center">
          <EyebrowMD weight="semibold">{availableDateText}</EyebrowMD>
        </div>
      </div>
    </div>
  )
}
