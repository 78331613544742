import React, { useCallback, useEffect, useRef, useState } from 'react'
import YouTube, { YouTubePlayer } from 'react-youtube'
import { LinkButton } from '@/atoms/Button'
import { CircleCloseIcon } from '@/atoms/Icons/CircleCloseIcon'
import { LoadingSpinnerIcon } from '@/atoms/Icons/LoadingSpinnerIcon'
import { Modal } from '@/molecules/Modal'

interface MarketingVideoPopupProps {
  ctaText: string
  ctaHref: string
}

export const MarketingVideoPopup: React.FC<MarketingVideoPopupProps> = ({ ctaText, ctaHref }) => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(true)
  const [youtubeId, setYoutubeId] = useState<string>()
  const playerRef = useRef<YouTubePlayer>()
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    const youtubeId = params.get('video')

    if (youtubeId) {
      setYoutubeId(youtubeId)
      setOpen(true)
    }
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      panelClasses="min-w-[80vw] lg:min-w-[55vw] !bg-transparent !p-0 !shadow-none"
    >
      <div className="py-4">
        <div className="flex flex-row items-end px-2 pb-2">
          <div
            className="h-8 w-8 cursor-pointer rounded-full bg-core-gray-950 transition-all duration-300 ease-in-out hover:opacity-80"
            onClick={handleClose}
          >
            <CircleCloseIcon color="core-gray-400" size={32} className="cursor-pointer" />
          </div>
        </div>
        <div className="flex flex-col items-center gap-6">
          <div className="aspect-h-9 aspect-w-16 h-full w-full">
            {isLoading && (
              <div className="z-50 flex items-center justify-center rounded-xl">
                <LoadingSpinnerIcon color="white" size={42} />
              </div>
            )}
            <YouTube
              onReady={(e) => {
                setLoading(false)
                playerRef.current = e.data
              }}
              opts={{ playerVars: { autoplay: 1, rel: 0 } }}
              iframeClassName="md:rounded-xl h-full w-full"
              videoId={youtubeId}
              id={youtubeId}
              className="bg-core-gray-950 md:rounded-xl"
              title=""
              onEnd={handleClose}
            />
          </div>
          <div className="w-fit">
            <LinkButton
              onClick={handleClose}
              href={ctaHref ?? ''}
              className="w-fit border-0 !bg-oxide px-8 py-3.5 !text-white focus:!bg-oxide-dark hover:!bg-oxide-dark active:!bg-oxide-dark"
              variant="white"
            >
              {ctaText}
            </LinkButton>
          </div>
        </div>
      </div>
    </Modal>
  )
}
