/**
 * AS: American Samoa
 * GU: Guam
 * MP: Northern Mariana Islands
 * PR: Puerto Rico
 * VI: U.S. Virgin Islands
 * https://en.wikipedia.org/wiki/Territories_of_the_United_States
 */

export const UNITED_STATES_AND_TERRITORIES = ['US', 'AS', 'GU', 'MP', 'PR', 'VI']
export const DOMESTIC_REGIONS = [...UNITED_STATES_AND_TERRITORIES, 'CA']
