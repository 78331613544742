import { ContentfulCloudinaryImageType } from '@/services/EmailCapturePageService/EmailCapturePageService'
import { GetGuildTheatricalReleasesResult } from '@/services/GuildReleasesService/TheatricalReleaseService'
import { formatContentfulObjectFromCloudinaryUrl } from '@/utils/Cloudinary'
import { useTranslate } from '@/utils/translate/translate-client'
import { Release } from './types'

const TWENTY_ONE_DAYS_IN_MILLIS = 60 * 60 * 24 * 21 * 1000

export function useTheatricalReleases(
  theatricalReleases: GetGuildTheatricalReleasesResult | undefined | null,
): Release[] {
  const { t } = useTranslate('join-the-guild')
  const futureReleases = useFutureReleases()

  if (!theatricalReleases) return []

  return theatricalReleases
    .filter(byFutureRelease)
    .map((release) => {
      return {
        name: release?.theatricalName as string,
        description: release?.shortSynopsis,
        releaseDate: release?.releaseDate,
        ...(release?.releaseDate && formatDate(release)),
        ...(!release?.releaseDate && formatCaption(release)),
        verticalPoster: release?.verticalPosters?.[0],
        horizontalPoster: release?.horizontalPosters?.[0],
        perk: t('twoComplimentaryTickets', '2 Complimentary Tickets'),
        perkType: 'tickets',
      }
    })
    .sort(byReleaseDateAsc)
    .concat([futureReleases])

  function formatCaption(release: GetGuildTheatricalReleasesResult[number]) {
    return {
      caption: t('tba', 'TBA'),
      captionSize: '20px',
      releaseYear: release?.filmDetails?.releaseYear,
    }
  }

  function formatDate(release: GetGuildTheatricalReleasesResult[number]) {
    const date = new Date(release?.releaseDate)
    return {
      releaseDay: date.getUTCDate().toString(),
      releaseMonth: date.toLocaleString('default', { month: 'short', timeZone: 'UTC' }),
      releaseYear: date.getUTCFullYear(),
    }
  }

  /**
   * Filters to release dates that are in theatres today or in the future, including TBA
   */
  function byFutureRelease(release: GetGuildTheatricalReleasesResult[number]) {
    const isTbaRelease = !release?.releaseDate
    const difference = new Date(release?.releaseDate).getTime() - midnight()
    const isTodayOrFuture = difference > 0 || Math.abs(difference) < TWENTY_ONE_DAYS_IN_MILLIS
    return isTbaRelease || isTodayOrFuture

    function midnight(date = new Date()): number {
      date.setUTCHours(0, 0, 0, 0)
      return date.getTime()
    }
  }

  /**
   * Sorts by releaseDate, chrono order, TBAs at the end
   */
  function byReleaseDateAsc(a: Release, b: Release) {
    const aReleaseDate = getClosestApproximationToReleaseDate(a)
    const bReleaseDate = getClosestApproximationToReleaseDate(b)

    if (!aReleaseDate) return 1
    if (!bReleaseDate) return -1

    return new Date(aReleaseDate).getTime() - new Date(bReleaseDate).getTime()
  }
}

function getClosestApproximationToReleaseDate(release: Release) {
  if (!release?.releaseYear && !release?.releaseDate) return null
  if (release?.releaseDate) return new Date(release.releaseDate)
  return new Date(
    parseInt(release.releaseYear as string),
    release.releaseMonth ? parseInt(release.releaseMonth) - 1 : 11,
    release.releaseDay ? parseInt(release.releaseDay) : 31,
  )
}

function useFutureReleases() {
  const { t } = useTranslate('join-the-guild')
  const futureReleases: Release = {
    name: t('futureTheatricalFilms', 'Future Theatrical Films'),
    description: t(
      'twoComplimentaryTicketsToEveryFilm',
      '2 complimentary tickets for every future Angel Studios theatrical film release.',
    ),
    caption: t('futureShows', 'Future Shows'),
    captionSize: '12px',
    verticalPoster: formatContentfulObjectFromCloudinaryUrl(
      'v1695943242/angel-studios/Upcoming_Theatrical_Poster.png',
    ) as ContentfulCloudinaryImageType,
    horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
      'v1695943242/angel-studios/Upcoming_Theatrical_Poster.png',
    ) as ContentfulCloudinaryImageType,
    perk: t('unlimitedValue', 'Unlimited Value'),
    perkType: 'unlimited',
  }

  return futureReleases
}
