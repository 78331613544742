import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const EpisodeIcon: React.FC<OneColorIconProps> = ({ size = 24, color = 'white', className }) => {
  const hex = getHexColor(color)

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 6C2 3.79086 3.79086 2 6 2H18C20.2091 2 22 3.79086 22 6V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6ZM6 4C4.89543 4 4 4.89543 4 6V7L8.09998 7V4H6ZM10.1 4V7L13.9 7V4H10.1ZM20 7L15.9 7V4H18C19.1046 4 20 4.89543 20 6V7ZM4 18V9L20 9V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18ZM10 12.3942V16.5257C10 16.9251 10.4451 17.1632 10.7774 16.9417L13.876 14.876C14.1728 14.6781 14.1728 14.2418 13.876 14.0439L10.7774 11.9782C10.4451 11.7567 10 11.9949 10 12.3942Z"
        fill={hex}
      />
    </svg>
  )
}
