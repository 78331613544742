import React from 'react'
import classNames from 'classnames'
import { Image } from '@/atoms/Image'
import { CaptionMD, CaptionSM, CaptionXS, EyebrowMD, TitleXS } from '@/atoms/Text'
import { useThemeContext } from '@/contexts/ThemeContext'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { useGuildSignupContext } from '@/views/GuildSignupView/GuildSignupContext'
import { GuildPromotionCountdown } from './GuildPromotionCountdown'

interface GuildPromotionBannerProps {
  hideNavigation?: boolean
}

export const GuildPromotionBanner: React.FC<GuildPromotionBannerProps> = (props) => {
  return <GuildPromotionBannerNew {...props} />
}

const GuildPromotionBannerNew: React.FC<GuildPromotionBannerProps> = ({ hideNavigation = false }) => {
  const { t } = useTranslate('guild')
  const { sale } = useGuildSignupContext()
  const { isDarkMode } = useThemeContext()

  if (!sale) return null

  return (
    <>
      {/* mobile version */}
      <div
        className={classNames('relative z-20 flex gap-x-2 min-h-[88px] w-full items-center bg-white px-4 md:hidden', {
          'top-0': hideNavigation,
          'top-[64px]': !hideNavigation,
          'border border-x-0 border-core-gray-200': !isDarkMode,
        })}
      >
        <div className="flex h-full w-full flex-col justify-center">
          <div className="flex flex-row items-center gap-2">
            <CaptionSM weight="bold">{sale.name}</CaptionSM>
          </div>
          {sale.endDate && (
            <>
              <CaptionXS className="uppercase !text-[#F45A3B]" weight="bold">
                <Translate i18nKey="endsIn" t={t}>
                  Ends In
                </Translate>
              </CaptionXS>
              <GuildPromotionCountdown endDate={sale.endDate} />
            </>
          )}
        </div>
        <div className="flex items-center justify-center">
          <CaptionMD color="core-gray-600" weight="bold">
            {sale.pitch}
          </CaptionMD>
        </div>
      </div>

      {/* desktop version */}
      <div
        className={classNames('relative z-20 hidden min-h-[88px] w-full bg-white md:block', {
          'top-0': hideNavigation,
          'top-[64px]': !hideNavigation,
          'border border-x-0 border-core-gray-200': !isDarkMode,
        })}
      >
        <Image
          className="absolute inset-y-0 left-0 h-full"
          alt="black friday banner image left"
          width={243}
          height={88}
          src="/v1725044109/angel-studios/shows-left.webp"
        />
        <Image
          className="absolute inset-y-0 right-0 hidden h-full lg:block"
          alt="black friday banner image right"
          width={243}
          height={88}
          src="/v1725044109/angel-studios/shows-right.webp"
        />
        <div className="absolute h-full w-full md:block">
          <div className="flex h-full justify-between gap-x-6 pl-64 lg:mx-12 lg:justify-between lg:pr-64">
            <div className="flex shrink-0 flex-col justify-center">
              <div className="flex flex-row items-center gap-2">
                <EyebrowMD weight="bold" className="lg:photon-title-md">
                  {sale.name}
                </EyebrowMD>
              </div>
              {sale.endDate && (
                <>
                  <CaptionSM className="uppercase" weight="bold" color="core-gray-600">
                    <Translate i18nKey="endsIn" t={t}>
                      Ends In
                    </Translate>
                  </CaptionSM>
                  <GuildPromotionCountdown endDate={sale.endDate as string} />
                </>
              )}
            </div>
            <div className="flex items-center justify-center">
              <TitleXS color="core-gray-600" weight="bold">
                {sale.pitch}
              </TitleXS>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

// The Old banner code is left below for now just in case something goes wrong with the new implementation
// so that we can quickly switch back. Delete this old component if it's been a couple months.

// const GuildPromotionBannerOld: React.FC<GuildPromotionBannerProps> = ({ hideNavigation = false }) => {
//   const { t } = useTranslate('guild')
//   const { locale } = useLocale()
//   const { promotion } = useGuildSignupContext()
//   const { isDarkMode } = useThemeContext()
//   if (!promotion) return null

//   const priceDisplay = getPromotionalPriceDisplay(promotion, promotion.offer.price, locale)
//   const normalPriceDisplay = formatPrice(24000, {
//     locale,
//     currency: promotion.offer.currency,
//     includeDecimals: false,
//   })
//   return (
//     <>
//       {/* mobile version */}
//       <div
//         className={classNames('relative z-20 flex min-h-[88px] w-full items-center bg-white px-4 md:hidden', {
//           'top-0': hideNavigation,
//           'top-[64px]': !hideNavigation,
//           'border border-x-0 border-core-gray-200': !isDarkMode,
//         })}
//       >
//         <div className="flex h-full w-full flex-col justify-center">
//           <div className="flex flex-row items-center gap-2">
//             <CaptionSM weight="bold">{promotion.title}</CaptionSM>
//             {promotion.endDate && <GuildPromotionCountdownSimplified endDate={promotion.endDate as string} />}
//           </div>
//           {promotion.endDate && (
//             <>
//               <CaptionXS className="uppercase" weight="bold" color="core-gray-600">
//                 <Translate i18nKey="endsIn" t={t}>
//                   Ends In
//                 </Translate>
//               </CaptionXS>
//               <GuildPromotionCountdown endDate={promotion.endDate as string} />
//             </>
//           )}
//           {!promotion.endDate && (
//             <TitleSM weight="bold" className="uppercase">
//               {t('guildMembership', 'Guild Membership')}
//             </TitleSM>
//           )}
//         </div>
//         <div className="flex h-full flex-col items-end justify-center gap-y-1">
//           <div className="flex items-center gap-x-2">
//             <If condition={promotion.applyToAllPlans && promotion.promoPercentDiscount}>
//               <Then>
//                 <div className="flex flex-col items-center justify-center">
//                   <TitleXXS color="core-gray-800" weight="bold" className="whitespace-nowrap">
//                     <Translate i18nKey="upTo40PercentOff" t={t}>
//                       Up to 40% Off
//                     </Translate>
//                   </TitleXXS>
//                   <CaptionMD color="core-gray-600" weight="bold" className="whitespace-nowrap">
//                     <Translate i18nKey="forAnEntireYear" t={t}>
//                       for an entire year!
//                     </Translate>
//                   </CaptionMD>
//                 </div>
//               </Then>
//               <Else>
//                 <TitleXS className="text-core-gray-800/50 line-through decoration-[#F45A3B]" weight="bold">
//                   {normalPriceDisplay}
//                 </TitleXS>
//                 <TitleSM color="core-gray-800" weight="bold">
//                   <Translate i18nKey="pricePerYear" t={t} values={{ priceDisplay }}>
//                     {{ priceDisplay }}/year
//                   </Translate>
//                 </TitleSM>
//               </Else>
//             </If>
//           </div>
//         </div>
//       </div>
//       {/* desktop version */}
//       <div
//         className={classNames('relative z-20 hidden min-h-[88px] w-full bg-white md:block', {
//           'top-0': hideNavigation,
//           'top-[64px]': !hideNavigation,
//           'border border-x-0 border-core-gray-200': !isDarkMode,
//         })}
//       >
//         <Image
//           className="absolute inset-y-0 left-0 h-full"
//           alt="black friday banner image left"
//           width={243}
//           height={88}
//           src="/v1725044109/angel-studios/shows-left.webp"
//         />
//         <Image
//           className="absolute inset-y-0 right-0 hidden h-full lg:block"
//           alt="black friday banner image right"
//           width={243}
//           height={88}
//           src="/v1725044109/angel-studios/shows-right.webp"
//         />
//         <div className="absolute h-full w-full md:block">
//           <div className="flex h-full justify-center gap-x-6 md:pl-52 lg:justify-center lg:pl-0">
//             <div className="flex flex-col justify-center">
//               <div className="flex flex-row items-center gap-2">
//                 <EyebrowMD weight="bold" className="lg:photon-title-md">
//                   {promotion.title}
//                 </EyebrowMD>
//                 {promotion.endDate && <GuildPromotionCountdownSimplified endDate={promotion.endDate as string} />}
//               </div>
//               {promotion.endDate && (
//                 <>
//                   <CaptionSM className="uppercase" weight="bold" color="core-gray-600">
//                     <Translate i18nKey="endsIn" t={t}>
//                       Ends In
//                     </Translate>
//                   </CaptionSM>
//                   <GuildPromotionCountdown endDate={promotion.endDate as string} />
//                 </>
//               )}
//               {!promotion.endDate && (
//                 <TitleSM weight="bold" className="lg:photon-heading-sm uppercase">
//                   {t('guildMembership', 'Guild Membership')}
//                 </TitleSM>
//               )}
//             </div>
//             <div className="flex flex-col items-end justify-center gap-y-1 lg:items-center">
//               <div className="flex items-center gap-x-2">
//                 <If condition={promotion.applyToAllPlans && promotion.promoPercentDiscount}>
//                   <Then>
//                     <div className="flex flex-col items-center justify-center">
//                       <TitleSM color="core-gray-800" weight="bold">
//                         <Translate i18nKey="upTo40PercentOff" t={t}>
//                           Up to 40% Off
//                         </Translate>
//                       </TitleSM>
//                       <TitleXS color="core-gray-600" weight="bold">
//                         <Translate i18nKey="forAnEntireYear" t={t}>
//                           for an entire year!
//                         </Translate>
//                       </TitleXS>
//                     </div>
//                   </Then>
//                   <Else>
//                     <TitleXS
//                       className="!text-core-gray-800/50 line-through decoration-[#F45A3B]"
//                       color="white"
//                       weight="bold"
//                     >
//                       {normalPriceDisplay}
//                     </TitleXS>
//                     <TitleLG className="" color="core-gray-800" weight="bold">
//                       <Translate i18nKey="pricePerYear" t={t} values={{ priceDisplay }}>
//                         {{ priceDisplay }}/year
//                       </Translate>
//                     </TitleLG>
//                   </Else>
//                 </If>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }
