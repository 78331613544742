import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Image } from '@/atoms/Image'
import { HeadingMD } from '@/atoms/Text'
import { options } from '@/services/ContentfulService/ContentfulService'
import { isGuildSignupMultiShowLayout } from '@/services/GuildSignupPageService/validations'
import { GuildCTAButton } from '@/views/GuildSignupView/GuildCTAButton'
import { useGuildSignupContext } from '@/views/GuildSignupView/GuildSignupContext'
import { AlreadyAGuildMember } from '@/views/GuildSignupView/GuildSingleShowLayout/GuildHeroCTA/AlreadyAGuildMember'

export const GuildMultiShowSmallHero = () => {
  const { layout } = useGuildSignupContext()

  if (!isGuildSignupMultiShowLayout(layout)) return null

  const { heading, paragraph, primaryImage } = layout

  const image = primaryImage?.[0]

  return (
    <div className="pt-16">
      <div className="relative flex w-screen items-center justify-center overflow-y-hidden bg-core-gray-100 px-4 pb-10 pt-4">
        <div className="relative flex max-w-lg flex-col items-start justify-center gap-y-6">
          <div className="flex flex-col gap-y-2">
            <div className="-mx-4 mb-4 flex justify-between">
              <div className="pl-4">
                <Image
                  src="/v1700075329/angel-studios/guild/Version_Guild_Angel_Copper_OnLight_Yes.webp"
                  height={100}
                  width={80}
                  alt="Angel Guild"
                  className="shrink-0 grow-0"
                />
              </div>
              <Image
                priority
                width={image?.width}
                height={image?.height}
                alt=""
                className="h-[150px] w-auto"
                src={`/w_400/v${image.version}/${image.public_id}.webp`}
              />
            </div>
            <div className="flex flex-col gap-y-4">
              <HeadingMD weight="bold">{heading}</HeadingMD>
              <span className="text-core-gray-800">{documentToReactComponents(paragraph.json, options)}</span>
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <GuildCTAButton />
            <AlreadyAGuildMember
              className="photon-label-xs flex gap-x-1 px-4 py-2 text-core-gray-700"
              linkClassName="photon-label-xs text-core-oxide"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
