import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const InfiniteIcon: React.FC<OneColorIconProps> = ({ size = 24, color = 'white', className }) => {
  const hex = getHexColor(color)

  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16659 5.24992C9.53388 4.76019 10.046 4.39845 10.6303 4.21592C11.2146 4.0334 11.8415 4.03934 12.4222 4.23293C13.003 4.42651 13.5081 4.79791 13.866 5.29451C14.224 5.79112 14.4166 6.38776 14.4166 6.99992C14.4166 7.61208 14.224 8.20872 13.866 8.70532C13.5081 9.20193 13.003 9.57333 12.4222 9.76691C11.8415 9.96049 11.2146 9.96644 10.6303 9.78392C10.046 9.60139 9.53388 9.23964 9.16659 8.74992L6.83325 5.24992C6.46596 4.76019 5.95389 4.39845 5.36957 4.21592C4.78526 4.0334 4.15833 4.03934 3.57759 4.23293C2.99684 4.42651 2.49173 4.79791 2.1338 5.29451C1.77586 5.79112 1.58325 6.38776 1.58325 6.99992C1.58325 7.61208 1.77586 8.20872 2.1338 8.70532C2.49173 9.20193 2.99684 9.57333 3.57759 9.76691C4.15833 9.96049 4.78526 9.96644 5.36957 9.78392C5.95389 9.60139 6.46596 9.23964 6.83325 8.74992"
        stroke={hex}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
