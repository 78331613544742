import { formatContentfulObjectFromCloudinaryUrl } from '@/utils/Cloudinary'
import { useTranslate } from '@/utils/translate/translate-client'
import { useGuildSignupContext } from '@/views/GuildSignupView/GuildSignupContext'
import { Release } from './Releases'

export function useStreamingReleases(): Release[] {
  const { t } = useTranslate('guild')
  const { slug } = useGuildSignupContext()

  const dryBar = {
    name: 'Dry Bar Comedy',
    description: t(
      'dryBarShortDescriptionV1',
      'Hundreds of stand up comedy specials that are truly funny for everyone.',
    ),
    releaseDay: null,
    releaseMonth: null,
    releaseYear: null,
    caption: t('watchNow', 'Watch Now'),
    captionSize: '12px',
    verticalPoster: formatContentfulObjectFromCloudinaryUrl('v1695672898/angel-studios/Drybar-Poster.webp'),
    horizontalPoster: formatContentfulObjectFromCloudinaryUrl('v1695672898/angel-studios/Drybar-Poster.webp'),
    perk: t('exclusiveSpecials', 'Exclusive Specials'),
    perkType: 'access',
  } as Release

  const list = [
    {
      name: 'Seven Days in Utopia',
      description: t(
        'utopiaShortDescription',
        'A young golfer is about to lose his dream when an eccentric rancher makes him a life-changing offer.',
      ),
      releaseDay: '9',
      releaseMonth: 'Jan',
      releaseYear: '2024',
      caption: null,
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1727907983/studio-app/catalog/0210dea0-6446-4b02-9d38-1d5436eb0826.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1727907944/studio-app/catalog/91de8785-04d0-456c-a268-9bb49ac66556.webp',
      ),
      perk: t('earlyAccess', 'Early Access'),
      perkType: 'access',
    },
    {
      name: 'The Riot and The Dance',
      description: t(
        'riotShortDescription',
        'A documentary exploring how nature was intentionally designed by a divine creator.',
      ),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1731957764/studio-app/catalog/389347f8-6ffa-42c4-b68b-614f32801649.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1731957754/studio-app/catalog/bde03317-a5b9-4ab1-a9b8-43b35bb04516.webp',
      ),
      perk: t('watchNow', 'Watch Now'),
      perkType: 'watch',
    },
    {
      name: 'Lost on a Mountain in Maine',
      description: t(
        'lostOnAMountainDescription',
        `The true story of a young boy's survival against the odds after getting lost in the wilderness.`,
      ),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1730156027/studio-app/catalog/689d2b93-6af3-4518-8ed8-d21c85d499cd.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1729891691/studio-app/catalog/4a32d1c9-b40e-4dfc-8a1b-e7b38bc236e2.webp',
      ),
      perk: t('watchNow', 'Watch Now'),
      perkType: 'watch',
    },
    {
      name: 'The Blind',
      description: t(
        'theBlindDescription',
        `Phil Robertson’s journey from addiction and turmoil to faith and redemption inspires hope.`,
      ),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1732053982/studio-app/catalog/f6f9f6bf-3b82-4cdc-a412-f49bb0ae09b9.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1732053127/studio-app/catalog/d6951b33-8928-4429-a3a3-48a4a9816e8f.webp',
      ),
      perk: t('watchNow', 'Watch Now'),
      perkType: 'watch',
    },
    {
      name: 'Redemption of Henry Myers',
      description: t(
        'redemptionHenryMyersDescription',
        `Left for dead after a failed bank robbery, Henry Myers must rely on a kind widow.`,
      ),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1731950698/studio-app/catalog/e7b1154b-15d7-4d29-bba9-1912be05fadc.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1731950689/studio-app/catalog/baf23403-e8fa-4606-aaba-d1472a9a9deb.webp',
      ),
      perk: t('watchNow', 'Watch Now'),
      perkType: 'watch',
    },
    {
      name: '23 Blast',
      description: t(
        'twentyThreeBlastDescription',
        `A high school football hero contracts an infection that renders him blind overnight.`,
      ),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1729201656/studio-app/catalog/7f3f0ca7-77cd-492d-b1dd-77ca0ac7a557.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1729201642/studio-app/catalog/026cec0b-40e8-4b38-a478-bf480f3b097c.webp',
      ),
      perk: t('watchNow', 'Watch Now'),
      perkType: 'watch',
    },
    {
      name: 'The Way',
      description: t(
        'theWayShortDescription',
        `A man whose son died while trekking the “Camino de Santiago” embarks on the pilgrimage himself.`,
      ),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1727906391/studio-app/catalog/c191b995-bd55-43ed-9c91-7acf6aa4223d.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1727906312/studio-app/catalog/7ba591a7-bcb7-4af5-8846-6b5922021247.webp',
      ),
      perk: t('watchNow', 'Watch Now'),
      perkType: 'watch',
    },
    {
      name: 'Sweetwater',
      description: t(
        'sweetwaterShortDescription',
        `Nat 'Sweetwater' Clifton is recruited to become the first African American in the NBA.`,
      ),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1728756960/studio-app/catalog/379e5e7b-fe81-4383-91f9-091a9e184125.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1728756954/studio-app/catalog/31567592-972c-400d-a100-f0383a6f9e4f.webp',
      ),
      perk: t('watchNow', 'Watch Now'),
      perkType: 'watch',
    },
    {
      name: 'Surprised by Oxford',
      description: t(
        'oxfordShortDescription',
        `As Caro starts a PhD at Oxford, a new friendship opens her up to mystery, vulnerability, and love.`,
      ),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1725054401/studio-app/catalog/4d399fe3-852f-42b2-8a58-29c16ee9ddfa.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1725054326/studio-app/catalog/eafad9c3-08fa-4626-9672-274103a18e29.webp',
      ),
      perk: t('watchNow', 'Watch Now'),
      perkType: 'watch',
    },
    {
      name: 'Something to Stand For',
      description: t(
        'somethingToStandforShortDescription',
        `Join America's favorite storyteller on an epic journey through defining movements in U.S. history.`,
      ),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1728709865/studio-app/catalog/341906eb-657e-4a33-852f-bdeb55d13f0d.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1724882662/studio-app/catalog/a54c937b-c830-477e-a391-0993b3084404.webp',
      ),
      perk: t('watchNow', 'Watch Now'),
      perkType: 'watch',
    },
    {
      name: 'Sound of Hope',
      description: t(
        'soundOfHopeShortDescription',
        'Twenty-two families from a rural Black church adopt seventy-seven kids from the foster system.',
      ),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1729266107/studio-app/catalog/7f049ade-480f-4711-bfaa-435712909e41.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1714755270/studio-app/catalog/2327f83a-fd99-4ca9-be8f-db14867e8f24.webp',
      ),
      perk: t('watchNow', 'Watch Now'),
      perkType: 'watch',
    },
    {
      name: 'Sight',
      description: t(
        'sightShortDescription',
        'A world-renowned eye surgeon is tasked with restoring the sight of a blind orphan.',
      ),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1728016767/studio-app/catalog/ee8a5087-c323-4548-a46f-c2185e71af0d.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1715980856/studio-app/catalog/9bfa8f77-d01e-4e2e-b38d-1321f613b9dd.webp',
      ),
      perk: t('watchNow', 'Watch Now'),
      perkType: 'watch',
    },
    {
      name: 'Sound of Freedom',
      description: t(
        'sofShortDescription',
        'Based on the true story of a man who journeys to rescue a girl from child traffickers.',
      ),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1728085377/studio-app/catalog/f92d6630-bbd4-4c85-b5a8-f738dee8ca1d.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1728085372/studio-app/catalog/64ff2c7b-529b-4503-8015-ac5abe61e607.webp',
      ),
      perk: t('watchNow', 'Watch Now'),
      perkType: 'watch',
    },
    {
      name: 'Cabrini',
      description: t(
        'cabriniShortDescription',
        `Francesca Cabrini risks everything when she petitions New York's mayor to house the city's orphans.`,
      ),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1727921347/studio-app/catalog/95aac1e1-8bcc-41b3-a315-f3b62c79052e.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1727921342/studio-app/catalog/0c235cca-47e8-4e24-aa83-4b8b03933b71.webp',
      ),
      perk: t('watchNow', 'Watch Now'),
      perkType: 'watch',
    },
    {
      name: 'The Shift',
      description: t(
        'shiftShortDescription',
        'A dystopian sci-fi thriller, one man is faced with infinite worlds and impossible choices.',
      ),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1728016182/studio-app/catalog/c6b24114-08f2-4b8e-bf5e-a2b76b1c4662.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1690324274/studio-app/catalog/fa200442-9dfd-4a28-9da3-15573554cdc0.webp',
      ),
      perk: t('watchNow', 'Watch Now'),
      perkType: 'watch',
    },
    {
      name: 'After Death',
      description: t('afterDeathShortDescription', 'Some saw light. Others saw darkness. All of them returned.'),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1727911807/studio-app/catalog/1e7150dc-e0d9-482b-8d47-f39c579bc97c.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1694190404/studio-app/catalog/12370111-e638-4dce-9135-a32826f41cd0.webp',
      ),
      perk: t('watchNow', 'Watch Now'),
      perkType: 'watch',
    },
    {
      name: 'Young DAVID',
      description: t(
        'youndDavidShortDescription',
        'When David’s quest to write a song lands him far from home, he must find his way back to his sheep.',
      ),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl('v1707932054/angel-app/young-david/added-logo.webp'),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1698960243/studio-app/catalog/290bcb6c-f097-4e53-bfb0-544bbde23806.webp',
      ),
      perk: t('watchNow', 'Watch Now'),
      perkType: 'watch',
    },
    {
      name: 'The Wingfeather Saga',
      description: t(
        'wfsShortDescription',
        `With Fangs in pursuit, the Wingfeathers flee to the Ice Prairies—where lizard Fangs can’t follow.`,
      ),
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1731948667/studio-app/catalog/c6de904e-2e04-4981-80ed-e7999b2b5d5d.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1668465114/angel-app/temp-discover/wingfeather-saga-landscape.jpg',
      ),
      perk: t('earlyAccess', 'Early Access'),
      perkType: 'access',
    },
    {
      name: 'Tuttle Twins Season 3',
      description: t(
        'tuttleShortDescription',
        'The Tuttle Twins go on adventures through time and learn about liberty and freedom.',
      ),
      caption: t('watchNow', 'Watch Now'),
      captionSize: '12px',
      releaseDay: null,
      releaseMonth: null,
      releaseYear: null,
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1728019125/studio-app/catalog/40d5e4b1-34d3-4eaa-a53d-499ceb005fbc.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1728019120/studio-app/catalog/cc4e723c-febc-4d0e-9ff9-2d234a15a71d.webp',
      ),
      perk: t('earlyAccess', 'Early Access'),
      perkType: 'access',
    },
    {
      name: 'Bonhoeffer',
      description: t(
        'bonhoefferShortDescription',
        'The true story of Dietrich Bonhoeffer, a pastor who plotted the assassination of Hitler.',
      ),
      releaseDay: null,
      releaseMonth: 'Q4',
      releaseYear: '2024',
      caption: null,
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1727921039/studio-app/catalog/06a13e32-c9d1-4121-8ff5-8914452714e7.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1727921035/studio-app/catalog/45f9bde5-2040-4a50-b7be-7b018e62480e.webp',
      ),
      perk: t('earlyAccess', 'Early Access'),
      perkType: 'access',
    },
    {
      name: 'Truth and Conviction',
      description: t(
        'truthAndConvictionShortDescription',
        'Driven by the Nazi arrest of his Jewish friend, a 16-year-old boy named Helmuth Hübener is compelled to take a stand.',
      ),
      releaseDay: null,
      releaseMonth: 'Q1',
      releaseYear: '2025',
      caption: null,
      captionSize: '12px',
      verticalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1727987584/studio-app/catalog/cb9f691c-686e-4c9c-9136-b27cdd5f2674.webp',
      ),
      horizontalPoster: formatContentfulObjectFromCloudinaryUrl(
        'v1727987835/studio-app/catalog/14f40902-3205-4669-8e07-fec57a2f877e.webp',
      ),
      perk: t('earlyAccess', 'Early Access'),
      perkType: 'access',
    },
  ] as Release[]

  if (slug === 'dry-bar-comedy') {
    list.unshift(dryBar)
  } else {
    list.splice(list.length - 2, 0, dryBar)
  }

  return list
}
