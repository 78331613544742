import dynamic, { DynamicOptions } from 'next/dynamic'
import { GuildHeroCTAProps } from './GuildHeroCTA'

export const GuildHeroCTA = dynamic(
  import('./GuildHeroCTA')
    .then((mod) => mod.GuildHeroCTA)
    .catch((err) =>
      // eslint-disable-next-line no-console
      console.error(`Failed to load the GuildHeroCTA!`, err),
    ) as DynamicOptions<GuildHeroCTAProps>,
  { ssr: false },
)
