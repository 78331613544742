import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Image } from '@/atoms/Image'
import { AsH1, AsSpan, HeadingXL, ParagraphXL } from '@/atoms/Text'
import { options } from '@/services/ContentfulService'
import { isGuildSignupMultiShowLayout } from '@/services/GuildSignupPageService/validations'
import { GuildCTAButton } from '@/views/GuildSignupView/GuildCTAButton'
import { useGuildSignupContext } from '@/views/GuildSignupView/GuildSignupContext'
import { AlreadyAGuildMember } from '@/views/GuildSignupView/GuildSingleShowLayout/GuildHeroCTA/AlreadyAGuildMember'

export const GuildMultiShowLargeHero = () => {
  const { layout } = useGuildSignupContext()

  if (!isGuildSignupMultiShowLayout(layout)) return null

  const { heading, paragraph, primaryImage } = layout
  const image = primaryImage?.[0]

  return (
    <div className="pt-16">
      <div className="flex items-center bg-core-gray-100">
        <div className="flex w-screen justify-end overflow-y-hidden p-10 md:pr-0">
          <div className="flex w-full flex-1 items-center justify-center">
            <div className="flex max-w-lg flex-col justify-center gap-y-6">
              <div className="flex flex-col gap-y-8">
                <Image
                  src="/v1700075329/angel-studios/guild/Version_Guild_Angel_Copper_OnLight_Yes.webp"
                  height={120}
                  width={96}
                  alt="Angel Guild"
                  className="shrink-0"
                />
                <div className="flex flex-col gap-y-4">
                  <HeadingXL as={AsH1} weight="bold">
                    {heading}
                  </HeadingXL>
                  <ParagraphXL as={AsSpan} color="core-gray-700">
                    {documentToReactComponents(paragraph.json, options)}
                  </ParagraphXL>
                </div>
              </div>
              <div className="flex flex-col gap-y-2">
                <GuildCTAButton />
                <AlreadyAGuildMember
                  className="photon-label-xs flex gap-x-1 px-4 py-2 text-core-gray-700"
                  linkClassName="photon-label-xs text-core-oxide"
                />
              </div>
            </div>
          </div>
          <div className="max-w-3xl flex-1">
            <Image
              priority
              width={image.width}
              height={image.height}
              alt=""
              src={`/w_800/v${image.version}/${image.public_id}.webp`}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
