import React from 'react'
import { isGuildSignupMultiShowLayout } from '@/services/GuildSignupPageService/validations'
import { GuildMultiShowLargeHero } from '@/views/GuildSignupView/GuildMultiShowHero/GuildMultiShowLargeHero'
import { GuildMultiShowSmallHero } from '@/views/GuildSignupView/GuildMultiShowHero/GuildMultiShowSmallHero'
import { useGuildSignupContext } from '@/views/GuildSignupView/GuildSignupContext'

export const GuildMultiShowHero = () => {
  const { layout } = useGuildSignupContext()

  if (!isGuildSignupMultiShowLayout(layout)) return null

  const { heading } = layout

  return (
    <section aria-label={heading}>
      <div className="md:hidden">
        <GuildMultiShowSmallHero />
      </div>
      <div className="hidden md:block">
        <GuildMultiShowLargeHero />
      </div>
    </section>
  )
}
