import React from 'react'
import { AsH2, HeadingXS, ParagraphSM } from '@/atoms/Text'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { useGuildSignupContext } from '@/views/GuildSignupView/GuildSignupContext'
import { ReleaseCard, useTheatricalReleases } from '@/views/GuildSignupView/Releases'
import { useStreamingReleases } from '@/views/GuildSignupView/data'

export const GuildUpcomingColumns: React.FC = () => {
  const { t } = useTranslate('guild')
  const { theatricalReleases } = useGuildSignupContext()
  const releases = useTheatricalReleases(theatricalReleases)

  const streamingReleases = useStreamingReleases()

  return (
    <section
      aria-label={t('upcomingTheatricalAndStreamingReleases', 'Upcoming Theatrical & Streaming Releases')}
      className="mb-12 flex flex-col items-center lg:w-full lg:flex-row lg:items-start lg:justify-center lg:gap-20"
    >
      <div className="relative max-w-[400px] py-6 se:max-w-[330px]">
        <HeadingXS as={AsH2} className="mb-3 !font-bold leading-8">
          <Translate i18nKey="theatricalReleases" t={t}>
            Theatrical Releases
          </Translate>
        </HeadingXS>
        <ParagraphSM className="se:photon-paragraph-xs pb-6 leading-7 text-gray-400">
          <Translate i18nKey="withYourAngelGuildMembershipReceive" t={t}>
            With your Angel Guild membership, you’ll receive 2 tickets at no additional cost for{' '}
            <span className="!font-bold text-white">every</span> Angel Studios theatrical release.*
          </Translate>
        </ParagraphSM>
        {releases?.map((release) => (
          <ReleaseCard release={release} key={release.key || release.name} />
        ))}
        <div className="absolute left-6 top-0 h-full pb-32 pt-56 sm:pt-48">
          <div className="h-full border-r border-dashed border-r-core-gray-700" />
        </div>
      </div>
      <div className="relative max-w-[400px] py-6 se:max-w-[330px]">
        <HeadingXS as={AsH2} className="mb-3 !font-bold leading-8">
          <Translate i18nKey="earlyAccess" t={t}>
            Early Access
          </Translate>
        </HeadingXS>
        <ParagraphSM className="se:photon-paragraph-xs max-w-[350px] pb-6 leading-7 text-gray-400">
          <Translate i18nKey="withYourAngelGuildMembershipReceiveEarlyAccess" t={t}>
            With your Angel Guild membership, you’ll receive early access to Angel Studios streaming releases.
          </Translate>
        </ParagraphSM>
        {streamingReleases.map((release) => (
          <ReleaseCard release={release} key={release.name} />
        ))}
        <div className="absolute left-6 top-0 h-full pb-32 pt-48">
          <div className="h-full border-r border-dashed border-r-core-gray-700" />
        </div>
      </div>
    </section>
  )
}
